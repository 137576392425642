import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Arlyn had a great talk with Kathleen Smith Shelton of the Center For Financial Services Innovation (CFSI) about some exciting milestones of this past year, how PayPerks defines success, and the advice for innovators breaking into underserved markets.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Kathleen:`}</strong>{` Do you have any thoughts for those just starting to look for ways to serve the underbanked markets?`}</p>
      <p parentName="blockquote"><strong parentName="p">{`Arlyn:`}</strong>{` My advice to entrepreneurs is always focus on solving problems -- not just creating a business. … That’s the best place to innovate, right? If you can solve a big problem in a big market, you have a business.`}</p>
    </blockquote>
    <p>{`Read more here: `}<a parentName="p" {...{
        "href": "http://www.cfsinnovation.com/content/thoughts-winner-2013-core-innovators-mega-challenge"
      }}>{`http://www.cfsinnovation.com/content/thoughts-winner-2013-core-innovators-mega-challenge`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      